<template>
  <div class="infoDetailWrap" v-loading.fullscreen="loading">
    <div class="infoDetail">
      <div class="header">
        <h1 class="title">{{ detail.name }}</h1>
        <p class="time">{{ detail.createTimeText }}</p>
      </div>
      <div class="content" v-html="detail.details"></div>
    </div>
    <!-- <div class="footer"><myfooter /></div> -->
  </div>
</template>

<script>
import { timeFormat } from '@/utils/index'
import { unescape } from 'html-escaper'
import { infoDetail } from '@/api/common'
// import myfooter from '@/components/footer'

export default {
  components: {
    // myfooter,
  },
  data() {
    return {
      id: '',
      detail: {},

      loading: true,
    }
  },
  created() {
    this.id = this.$route.params.id
    this.getInfoDetail()
  },
  methods: {
    async getInfoDetail() {
      try {
        let { data } = await infoDetail({
          id: this.id,
        })
        setTimeout(() => {
          this.loading = false
        }, 500)
        data.createTimeText = timeFormat(data.time * 1000, 'YYYY-M-D')
        data.details = unescape(data.details)
        this.detail = data
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
  },
}
</script>

<style lang="scss">
.infoDetailWrap {
  min-width: 1160px;
  padding: 70px 0;
  background: #f4f5f9;
  .infoDetail {
    width: 1160px;
    // padding: 100px 0;
    margin: 0 auto;
   
    .header {
      text-align: left;
       background: #fff;
      padding: 20px;
      .title {
        font-size: 34px;
        font-weight: bold;
        color: #383838;
        line-height: 44px;
        position: relative;
        &::after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: -9px;
          width: 61px;
          height: 6px;
          background-color: #f5c02f;
        }
      }
      .time {
        font-size: 16px;
        color: #828282;
        line-height: 32px;
        margin-top: 30px;
      }
    }
    .content {
       background: #fff;
       padding: 20px;
      margin-top: 40px;
      & /deep/ p,
      div,
      span {
        text-align: left;
        color: #727272;
        font-size: 22px;
        line-height: 32px;
      }
      & /deep/ p img {
        display: inline-block;
      }
    }
  }
  .footer {
    height: 280px !important;
    background-color: #f5c02f;
  }
}
</style>
